import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

// Context import
import BlockchainContext from "../../context/BlockchainContext";

import Exchange from "../Exchange";
import Subscribe from "../Subscribe";

const Banner = () => {
  const blockchainContext = useContext(BlockchainContext);
  const { connectHandler, disconnectHandler, blockchainData } = blockchainContext;
  const { account } = blockchainData;

  return (
    <section id="banner" className="banner">
      <div className="logo">
        <img src={require("../../assets/img/logo-white.svg")} alt="" />
      </div>
      <div className="telegram hidden-lg">
        <a href="https://t.me/RunexSupportOfficial" target="_blank" rel="noopener noreferrer" className="social">
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.9658 1.57781L15.2469 14.2817C15.0418 15.1783 14.5068 15.4014 13.7466 14.9792L9.60391 11.9545L7.60503 13.8594C7.38388 14.0785 7.19882 14.2619 6.77248 14.2619L7.07007 10.0816L14.7482 3.20762C15.082 2.91277 14.6758 2.74936 14.2293 3.04425L4.73732 8.96581L0.650908 7.69864C-0.237966 7.42366 -0.254024 6.81794 0.835897 6.39557L16.8195 0.294685C17.5595 0.0197447 18.2071 0.45802 17.9658 1.57781Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <h1>Runex - run your exchange</h1>
            <p>
              Development of decentralized systems <br /> and <span>algorithms</span>
            </p>
            <div className="btn-groups">
              {window.ethereum ? (
                <a onClick={connectHandler} className={`btn-st connect ${account ? "connected" : ""}`}>
                  {account ? "Connected" : "Connect wallet"}
                </a>
              ) : (
                <div className="btn-wallet">
                  <a href="https://metamask.io/download" className="btn-st connect">
                    Install wallet
                  </a>
                  <b>or use</b>
                  <a onClick={connectHandler} className="btn-st connect">
                    WalletConnect
                  </a>
                </div>
              )}
              <a href="#use" className="btn-st transparent">
                How to use
              </a>
              {account ? (
                <a onClick={disconnectHandler} className={`btn-st connect ${account ? "connected" : ""}`}>
                  Disconnect
                </a>
              ) : (
                <></>
              )}
            </div>
            <img className="logo-man" src={require("../../assets/img/banner.png")} alt="" />

            <Exchange />
          </Col>

          <Col lg={12}>
            <div className="info">
              <h6>To buy Rex tokens and send a staking you need to:</h6>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
                  <g>
                    <path
                      fill="#4478ff"
                      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 14a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0zm-1-7a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"
                    />
                  </g>
                </svg>
                <i>For PC version:</i> have the Metamask add-on installed on your browser. Click <i>"Сonnect wallet"</i>. All
                fees are paid in Eth, so you should have Eth in the wallet.
              </p>

              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
                  <g>
                    <path
                      fill="#4478ff"
                      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 14a1 1 0 0 1-2 0v-5a1 1 0 0 1 2 0zm-1-7a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"
                    />
                  </g>
                </svg>
                <i>For mobile version:</i> install and follow the browser link in the Metamask mobile application. Enter address
                runex.org in the browser link. Click <i>"Сonnect wallet"</i>. All fees are paid in Eth, so you should have Eth
                in the wallet.
              </p>
            </div>

            <div className="video">
              <h6>
                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 100 100" viewBox="0 0 100 100">
                  <switch>
                    <g>
                      <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4 c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" />
                      <path
                        fill="#4478ff"
                        d="M5268.4 2410.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.6-.4-1-1-1H5268.4zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1C5273.7 2414.1 5273.3 2413.7 5272.7 2413.7zM5272.7 2417h-4.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1C5273.7 2417.5 5273.3 2417 5272.7 2417zM50 2.5C23.8 2.5 2.5 23.8 2.5 50c0 26.2 21.3 47.5 47.5 47.5 26.2 0 47.5-21.3 47.5-47.5C97.5 23.8 76.2 2.5 50 2.5zM67.2 52.8L41.6 69.8c-2.2 1.5-5.1-.1-5.1-2.8V32.9c0-2.6 2.9-4.2 5.1-2.8l25.6 17.1C69.2 48.6 69.2 51.4 67.2 52.8z"
                      />
                    </g>
                  </switch>
                </svg>
                Video message from the Runex team
              </h6>
              <iframe
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/ZXFy0Vb3I78"
                title="Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </Col>

          <Col lg={12} className="hidden-lg">
            <Subscribe />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
