import React, { useEffect, useState, useContext } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

// Contexts import
import ExchangeContext from "../../context/ExchangeContext";
import BlockchainContext from "../../context/BlockchainContext";
import StakeContext from "../../context/StakeContext";
import Web3 from "web3";

const BN = Web3.utils.BN;
const fromWei = Web3.utils.fromWei;
const toWei = Web3.utils.toWei;

export default function StackingForm() {
  const { balances } = useContext(ExchangeContext);
  const { stake } = useContext(StakeContext);
  const { blockchainData, setBlockchainData, reboot } = useContext(BlockchainContext);
  const { allowance, error } = blockchainData;
  const { runBalance, tetherBalance } = balances;
  const [amountStake, setAmountStake] = useState("");
  const [loader, setLoader] = useState(true);
  const [approved, setApproved] = useState(false);

  const [tokenBalances, setTokenBalance] = useState({
    runBalance: "0",
    tetherBalance: "0",
  });

  const stakeHandler = async (e) => {
    e.preventDefault();

    if (amountStake) {
      const result = await stake(amountStake);

      if (result) {
        setTokenBalance({
          runBalance,
          tetherBalance,
        });
        setBlockchainData({ ...blockchainData, allowance: "0" });
        setApproved(false);
        setLoader(!loader);
      } else {
        await reboot();
        setLoader(!loader);
      }
    }
  };

  const stakeAllButton = () => {
    const valueAll = `${Math.floor(runBalance * 10000) / 10000}`;
    setAmountStake(valueAll);
  };

  useEffect(() => {
    setTokenBalance({
      runBalance,
      tetherBalance,
    });
    // checkApprove();
  }, [loader, balances, error, blockchainData]);

  return (
    <>
      <div className="form-block">
        <Form onSubmit={stakeHandler}>
          <Row>
            <Form.Group as={Col} md="12" className="mb-30">
              <Form.Label htmlFor="input">
                Input
                <span>
                  Balance: <b>{tokenBalances.runBalance === "0" ? runBalance : tokenBalances.runBalance}</b>
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="input"
                  name="input"
                  type="number"
                  placeholder={"Enter amount"}
                  value={amountStake}
                  step="0.0001"
                  min="0.0001"
                  max={runBalance}
                  onChange={(event) => {
                    setAmountStake(event.target.value);
                  }}
                  required
                />
                <InputGroup.Text>
                  <img src={require("../../assets/img/runex.svg")} alt="" />
                  REX
                </InputGroup.Text>
                <span className="all" onClick={() => stakeAllButton()}>
                  All
                </span>
              </InputGroup>
            </Form.Group>
          </Row>
          <div className="text-center">
            {approved ? (
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ad93dc"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="8" x2="12" y2="12" />
                  <line x1="12" y1="16" x2="12.01" y2="16" />
                </svg>
                {allowance + ' USDT to buy REX approved to continue click "SWAP!"'}
              </h5>
            ) : (
              <span></span>
            )}
            <button type="submit" className="btn-st transparent">
              STAKE!
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}
