import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// Components import
import Home from "../pages/Home";
import NavBar from "../pages/NavBar";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import Contact from "../pages/Contact";
import FAQ from "../pages/FAQ";
import Footer from "../pages/Footer";
import ScrollToPage from "../utils/ScrollToPage";
import LeftBar from "../pages/LeftBar";

const Main = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <LeftBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<BlogDetails />} />
        <Route path="contacts" element={<Contact />} />
        <Route path="faq" element={<FAQ />} />
      </Routes>
      <Footer />
      <ScrollToTop
        smooth
        color="#3d96d8"
        viewBox="0 0 24 24"
        svgPath="M17,13.41,12.71,9.17a1,1,0,0,0-1.42,0L7.05,13.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0L12,11.29l3.54,3.54a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,17,13.41Z"
      />
      <ScrollToPage />
    </BrowserRouter>
  );
};

export default Main;
