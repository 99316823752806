import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Subscribe() {
  return (
    <div className="subscribe hidden-lg">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="box">
              <h2>Documents Runex</h2>
              <div className="social">
                <div className="btn-groups">
                  <a href="https://docs.runex.org/" target="_blank" rel="noopener noreferrer" className="btn-st">
                    Documents
                  </a>
                  <a
                    href="https://etherscan.io/token/0x5bf844122dB21Ae6f787DddA9806AA7dd6489998"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-st"
                  >
                    Etherscan
                  </a>
                </div>
                <h2>Subscribe To Our Social Media</h2>
                <ul>
                  <li>
                    <a href="https://twitter.com/RunexTech" target="_blank" rel="noopener noreferrer">
                      <img src={require("../assets/img/social/twitter.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/RunexTechnologiesOfficial" target="_blank" rel="noopener noreferrer">
                      <img src={require("../assets/img/social/telegram-plane.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.reddit.com/user/RunexTechnologies" target="_blank" rel="noopener noreferrer">
                      <img src={require("../assets/img/social/reddit.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/runex-technologies-2572ba237/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={require("../assets/img/social/linkedin.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/runextechnologies/" target="_blank" rel="noopener noreferrer">
                      <img src={require("../assets/img/social/instagram.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/RunexTechnologies-116007887750743"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={require("../assets/img/social/facebook.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@runex.technolgy" target="_blank" rel="noopener noreferrer">
                      <img src={require("../assets/img/social/medium.svg")} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC6yo-XjNRT7E1MMrJUGYQgA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={require("../assets/img/social/youtube.svg")} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Subscribe;
