import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="about">
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <div className="title">
              <img src={require("../../assets/img/logo-white.svg")} alt="" />
              <h1>What is RUNEX?</h1>
            </div>
            <p className="text">
              Runex is a provider of decentralized trading services. The main objective of Runex algorithms under development is
              to create a fully automated decentralized platform, operating on based on smart contracts and providing extensive
              tools for working with crypto assets. Our task is to combine the positive features of already functioning trading
              service provider platforms and provide a product as much close to perfect market parameters.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <div className="title">
              <h1>Our Dex</h1>
            </div>
          </Col>
        </Row>
        <Row className="hidden-md">
          <Col lg={6} md={6}>
            <div className="item">
              <img src={require("../../assets/img/icon/1.png")} alt="" />
              <h3>OrderBook</h3>
              <p>
                Order matching is carried out based on OrderBook. You won't notice the difference between usage of regular
                centralised exchange and Runex exchange.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6}>
            <div className="item">
              <img src={require("../../assets/img/icon/2.png")} alt="" />
              <h3>Security</h3>
              <p>
                Security - it is impossible to change trading algorithms without user's permission. Possibility of mixers usage
                for anonymous deposit and withdrawal of funds. Continuous work of trading platform is granted.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6}>
            <div className="item">
              <img src={require("../../assets/img/icon/3.png")} alt="" />
              <h3>Huge trading tool set</h3>
              <p>
                You are able to use such tools as derivatives, open position to short, limit and market orders, stop loss, take
                profit.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6}>
            <div className="item">
              <img src={require("../../assets/img/icon/4.png")} alt="" />
              <h3>Comfort</h3>
              <p>
                Quick response and work of the Runex trading platform. User-friendly trading system. Interaction with native
                token without wrapped tokens.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="hidden-lg">
          <Slider {...settings}>
            <div className="item">
              <img src={require("../../assets/img/icon/1.png")} alt="" />
              <h3>OrderBook</h3>
              <p>
                Order matching is carried out based on OrderBook. You won't notice the difference between usage of regular
                centralised exchange and Runex exchange.
              </p>
            </div>
            <div className="item">
              <img src={require("../../assets/img/icon/2.png")} alt="" />
              <h3>Security</h3>
              <p>
                Security - it is impossible to change trading algorithms without user's permission. Possibility of mixers usage
                for anonymous deposit and withdrawal of funds. Continuous work of trading platform is granted.
              </p>
            </div>
            <div className="item">
              <img src={require("../../assets/img/icon/3.png")} alt="" />
              <h3>Huge trading tool set</h3>
              <p>
                You are able to use such tools as derivatives, open position to short, limit and market orders, stop loss, take
                profit.
              </p>
            </div>
            <div className="item">
              <img src={require("../../assets/img/icon/4.png")} alt="" />
              <h3>Comfort</h3>
              <p>
                Quick response and work of the Runex trading platform. User-friendly trading system. Interaction with native
                token without wrapped tokens.
              </p>
            </div>
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default About;
