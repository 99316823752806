import React, {useEffect, useState} from "react";
import Web3 from "web3";
// Routers
import Main from "./Routers/Main";
//Wallet
import Web3Modal from "web3modal";
import { providerOptions } from './wallet/providerOptions'
import { networkParams } from "./wallet/networks";
import { toHex, truncateAddress } from "./wallet/utils";

// ABIs
import RexExchangeAbi from "./abis/Exchange.json";
import RexTokenAbi from "./abis/RTToken.json";
import TetherTokenAbi from "./abis/TetherToken.json";
import StakeAbi from "./abis/Stakeable.json"

// Context
import BlockchainContext from "./context/BlockchainContext";
import ExchangeContext from "./context/ExchangeContext";
import StakeContext from "./context/StakeContext";


//Utils
const BN = Web3.utils.BN;
const fromWei = Web3.utils.fromWei
const toWei = Web3.utils.toWei

const mode =  process.env.REACT_APP_MODE
// Contract addresses
const contractAddresses = {
    prod: {

        rexExchangeAddress: process.env.REACT_APP_REX_EXCHANGE_ADDRESS,
        stakeAddress: process.env.REACT_APP_STAKE_ADDRESS,
        rexTokenAddress: process.env.REACT_APP_REX_TOKEN_ADDRESS,
        tetherTokenAddress: process.env.REACT_APP_TETHER_TOKEN_ADDRESS,

    }
}

console.log({contractAddresses})

const rexExchangeAddress = contractAddresses[mode].rexExchangeAddress

const rexTokenAddress = contractAddresses[mode].rexTokenAddress

const tetherTokenAddress = contractAddresses[mode].tetherTokenAddress

const stakeAddress = contractAddresses[mode].stakeAddress

const web3Modal = new Web3Modal({
    // cacheProvider: false, // optional
    theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
    },
    providerOptions // required
});




export default function App() {
    const [provider, setProvider] = useState({
        walletInstalled: true,
        account: "",
        exchangeContract: {},
        tetherTokenContract: {},
        rexTokenContract: {},
        stakeContract:{},
        allowance: '0',
        loading: true,
    });
    const [blockchainData, setBlockchainData] = useState({
        walletInstalled: true,
        account: "",
        exchangeContract: {},
        tetherTokenContract: {},
        rexTokenContract: {},
        stakeContract:{},
        allowance: '0',
        loading: true,
    });

    const [balances, setBalances] = useState({
        allowance: '0',
        runBalance: "0",
        tetherBalance: "0",
        exchangeBalance: '0'
    });

    // const [error, setError] = useState(undefined)

    const[summery, setSummery] = useState({
        total_supplay: '0',
        stakes: [{
            amount: "0",
            rewardPercent: "0",
            claimable: "0",
            since: "0",
            user: "0x0000000000000000000000000000000000000000"

        }]
    })



    const connectHandler = async () => {
        await loadWeb3();
        if (window.web3) {
            await loadBlockchainData();
            window.ethereum.on("chainChanged", async () => {
                await loadWeb3();
                await loadBlockchainData();
            });
            window.ethereum.on("accountsChanged", async () => {
                await loadWeb3();
                await loadBlockchainData();
            });
            window.ethereum.on("disconnect", disconnectHandler);
        } else {
            console.log("get MetaMask")
        }

    };

    const disconnectHandler = async () => {
        await web3Modal.clearCachedProvider();

        setBlockchainData({
            walletInstalled: true,
            account: "",
            exchangeContract: {},
            tetherTokenContract: {},
            rexTokenContract: {},
            stakeContract:{},
            allowance: '0',
            loading: true,
        });

        setBalances({
            allowance: '0',
            runBalance: "0",
            tetherBalance: "0",
            exchangeBalance: '0'
        });
        // await web3Modal.clearCachedProvider();
        console.log("disconnect")
    };

    function isMobileDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }

    const loadWeb3 = async () => {

        window.ethereum  = await web3Modal.connect()
        setProvider(window.ethereum)
        window.web3 = new Web3(window.ethereum);
        const chainId = await window.web3.eth.getChainId()

        if (chainId !== 1){
            // console.log(chainId, window.ethereum)
            await switchNetwork(1)
        }


        // if (window.ethereum) {
        //     window.web3 = new Web3(window.ethereum);
        //
        // } else if (window.web3) {
        //     window.web3 = new Web3(window.web3.currentProvider);
        // } else {
        //     console.log('non connected')
        // }
    };

    const switchNetwork = async (network) => {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: toHex(network) }]
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [networkParams[toHex(network)]]
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const addTokenInfoToMetamask = async () => {
        const tokenAddress = contractAddresses[mode].rexTokenAddress;
        const tokenSymbol = 'REX';
        const tokenDecimals = 18;
        const tokenImage = 'https://etherscan.io/token/images/runextech_32.png';

        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const loadBlockchainData = async () => {
        const web3 = window.web3;
        const account = (await web3.eth.getAccounts())[0];
        let summery;
        let {
            rexTokenContract,
            exchangeContract,
            stakeContract,
            tetherTokenContract,
            allowance
        } = blockchainData;

        exchangeContract = new web3.eth.Contract(
            RexExchangeAbi.abi,
            rexExchangeAddress
        );

        stakeContract = new web3.eth.Contract(
            StakeAbi.abi,
            stakeAddress
        );

        tetherTokenContract = new web3.eth.Contract(
            TetherTokenAbi.abi,
            tetherTokenAddress
        );

        rexTokenContract = new web3.eth.Contract(RexTokenAbi.abi, rexTokenAddress);

        try{
            tetherTokenContract.methods.allowance(account, rexExchangeAddress)
                .call({from:account})
                .then(async (res) => {

                    allowance = fromWei(res.toString(), "mwei");
                    await checkBlockchainData();
                })
        } catch (e) {
            console.error( e)
        }

        try{
            stakeContract.methods.hasStake(account)
                .call({from:account})
                .then(async(stakes) => {
                    summery = stakes
                    await checkBlockchainData();
                })
        }catch (e) {
            console.error(e)
        }

        const checkBlockchainData = async () => {

            if (
                web3 &&
                account &&
                exchangeContract &&
                stakeContract &&
                tetherTokenContract &&
                rexTokenContract &&
                summery &&
                allowance
            ) {
                setSummery(summery)
                await getBalances(tetherTokenContract, rexTokenContract, account);
                setBlockchainData({
                    ...blockchainData,
                    account,
                    exchangeContract,
                    stakeContract,
                    tetherTokenContract,
                    rexTokenContract,
                    allowance,
                    loading: false,
                });

                // tetherTokenContract.events.Approval(
                //     {
                //         filter: {owner: rexExchangeAddress, spender: account},
                //     },
                //     async (error, event) => {
                //         console.log({event, error});
                //     }
                // );
            }
        };
    };

    const getBalances = async (
        tetherTokenContract,
        rexTokenContract,
        account
        ) => {
        console.log({account})
        const web3 = window.web3;
        let runBalance, tetherBalance, allowance, exchangeBalance;
        try {
            const rexBalanceWai = await rexTokenContract.methods
                .balanceOf(account)
                .call({from:account});
            runBalance = web3.utils.fromWei(rexBalanceWai.toString(), "ether");
        }catch (e) {
            console.log(e)
        }


        try {
            const rexBalanceWai = await rexTokenContract.methods
                .balanceOf(rexExchangeAddress)
                .call({from:account});
            exchangeBalance = web3.utils.fromWei(rexBalanceWai.toString(), "ether");
        }catch (e) {
            console.log(e)
        }

        try {
            const tetherBalanceWai = await tetherTokenContract.methods
                .balanceOf(account)
                .call({from:account});

            tetherBalance = Number(tetherBalanceWai)/10**6
        }catch (e) {
            console.log(e)
        }

        try {
            const allowanceWai = await tetherTokenContract.methods.allowance(account, rexExchangeAddress)
                .call({from:account})
            allowance = Number(allowanceWai)/10**6
            setBalances({...balances, runBalance, tetherBalance, allowance, exchangeBalance});
        }catch (e) {
            console.log(e)
        }
    };

    const stake = async (amount) => {
        amount = toWei(amount, "ether");
        console.log({ amount });
        const {rexTokenContract, stakeContract, account } = blockchainData
        const approved = await rexTokenContract.methods.allowance(account, stakeAddress).call({from:account})
        const approvedBN = new BN(approved)
        const amountBN = new BN(amount)
        let approveTrx, stakeTrx;
      
        setBlockchainData({...blockchainData, loading: true});


        if (approvedBN.gte(amountBN)) {
            try {
                stakeTrx = await stakeContract.methods
                    .stake(approved)
                    .send({from: account});
                console.log({stakeTrx});
                return true
            } catch (error) {
                console.error(error.message)
                return false
            }
        } else {
            try {
                approveTrx = await rexTokenContract.methods
                    .approve(stakeAddress, amount )
                    .send({from: account});
                try {
                    if (approveTrx.status) {
                        stakeTrx = await stakeContract.methods
                            .stake(amount)
                            .send({from: account});
                        const approved = await rexExchangeAddress.methods.allowance(stakeAddress, account).call()
                        setBlockchainData({...blockchainData, loading: false, allowance: approved.toString()});
                    }
                } catch (e) {
                    console.error(e)
                    // setError('BUY_ERROR')
                    setBlockchainData({...blockchainData, loading: false});
                    return false
                }

            } catch (e) {
                console.error(e)
                // setError('APPROVE_ERROR')
                setBlockchainData({...blockchainData, loading: false});
                return false
            }

        }
    }

    const calculateTotalRewards = (stakes) => {
        const nullAddress = "0x0000000000000000000000000000000000000000"
        let totalRewards = new BN(0);
        if(stakes.length > 0) {
            stakes.forEach((item) => {
                if(item.user !== nullAddress){
                    const stakeReward = new BN(item.claimable)
                    totalRewards = totalRewards.add(stakeReward)

                }
            })
        }
        return fromWei(totalRewards, "ether")
    }

    const withdraw = async (amount, stake_index) => {

        const {tetherTokenContract, runTokenContract, stakeContract, account } = blockchainData

        let stakeTrx;
            try {
                stakeTrx = await stakeContract.methods
                    .withdrawStake(amount, stake_index)
                    .send({from: account});
                console.log({stakeTrx});
                if(window.web3) {
                    try {
                        let summery = await stakeContract.methods.hasStake(account)
                            .call()
                        setSummery(summery)
                    } catch (e) {

                    }
                }
                await getBalances(tetherTokenContract, runTokenContract, account)
                return true
            } catch (error) {
                console.error(error.message)
                return false
            }
    }

    const buyToken = async (amount) => {
        amount = toWei(amount, 'mwei')
        const {
            tetherTokenContract,
            rexTokenContract,
            exchangeContract,
            account,
        } = blockchainData;

        setBlockchainData({...blockchainData, loading: true});

        let approveTrx, buyTokenTrx, exchangeBalance
        try{
            exchangeBalance = await rexTokenContract.balanceOf(rexExchangeAddress)
            console.log(+amount/0.15 > +exchangeBalance)

        } catch (e){
            console.log(e)
        }

        try {
            const approved = await tetherTokenContract.methods.allowance(account, rexExchangeAddress).call()
            const approvedBN = new BN(approved)
            const amountBN = new BN(amount)

            if (approvedBN.gte(amountBN)) {
                try {
                    buyTokenTrx = await exchangeContract.methods
                        .buyToken(amount)
                        .send({from: account, gasLimit:210000, gaspPrice: await window.web3.eth.getGasPrice()});//,  ,,
                    console.log(buyTokenTrx);
                    await getBalances(tetherTokenContract, rexTokenContract, account);
                    setBlockchainData({...blockchainData, loading: false});
                } catch (error) {
                    console.error(error.message)
                    setBlockchainData({...blockchainData, loading: false});
                    return false
                }
            } else {
                try {
                    approveTrx = await tetherTokenContract.methods
                        .approve(rexExchangeAddress, amount)
                        .send({from: account});
                } catch (error) {
                    console.error(error.message)
                    // setError('APPROVE_ERROR')
                    setBlockchainData({...blockchainData, loading: false});
                    return false
                }
            try {
                if (approveTrx.status) {
                    buyTokenTrx = await exchangeContract.methods
                        .buyToken(amount)
                        .send({from: account});
                    await getBalances(tetherTokenContract, rexTokenContract, account);
                    const approved = await tetherTokenContract.methods.allowance(account, rexExchangeAddress).call()
                    setBlockchainData({...blockchainData, loading: false, allowance: approved.toString()});
                }
            } catch (error) {
                console.error(error.message)
                console.log({buyTokenTrx})
                // setError('BUY_ERROR')
                setBlockchainData({...blockchainData, loading: false});
                return false
            }
        }
        } catch
            (error) {
            // setError('TRANSACTION_ERROR')
            console.error(error.message)
            setBlockchainData({...blockchainData, loading: false});
            return false
        }
        return true
    };



    // useEffect(() => {
    //     const reboot = async () => {
    //         connectHandler()
    //     }
    // }, [error])

    const redirectIfMobileDevice = () => {
        if (isMobileDevice()) {
            const dappUrl = "runex.org/"
            const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl
            window.location.replace(metamaskAppDeepLink)
        }
    }


    useEffect(() => {
        const loadWeb3 = async () => {
            if (window.ethereum && window.ethereum.isMetaMask) {
                window.web3 = new Web3(window.ethereum);
                await window.ethereum.enable();
            } else if (window.web3) {
                window.web3 = new Web3(window.web3.currentProvider);
            } else {
                if(window.ethereum && window.ethereum.isMetaMask){
                    redirectIfMobileDevice()
                }
            }
        };

        loadWeb3()
    }, [])


    useEffect(() => {
        if (provider.on) {
            // const handleAccountsChanged = (accounts) => {
            //     console.log("accountsChanged", accounts);
            //     if (accounts) setAccount(accounts[0]);
            // };
            //
            // const handleChainChanged = (_hexChainId) => {
            //     setChainId(_hexChainId);
            // };

            const handleDisconnect = () => {
                console.log("disconnect");
                disconnectHandler();
            };

            // provider.on("accountsChanged", handleAccountsChanged);
            // provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    // provider.removeListener("accountsChanged", handleAccountsChanged);
                    // provider.removeListener("chainChanged", handleChainChanged);
                    provider.removeListener("disconnect", handleDisconnect)
                }
            };
        }
    }, [provider]);



    return (
        <div>
            <BlockchainContext.Provider
                value={{
                    blockchainData,
                    setBlockchainData,
                    buyToken,
                    loadBlockchainData,
                    connectHandler,
                    disconnectHandler,
                    reboot: connectHandler,
                    addTokenInfoToMetamask,
                    // error
                }}
            >
                <ExchangeContext.Provider
                    value={{
                        buyToken,
                        balances,
                    }}
                >
                    <StakeContext.Provider
                        value={{
                            stake,
                            withdraw,
                            getBalances,
                            calculateTotalRewards,
                            summery,
                            setSummery
                        }}>
                        <Main/>
                    </StakeContext.Provider>
                </ExchangeContext.Provider>
            </BlockchainContext.Provider>
        </div>
    );
}
