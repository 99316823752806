import React, { useContext } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import BlockchainContext from "../context/BlockchainContext";
import BuyForm from "./components/BuyForm";
import StackingForm from "./components/StackingForm";
import StakesList from "./components/StakesList";

const Exchange = () => {
  const { blockchainData, connectHandler } = useContext(BlockchainContext);
  const { account } = blockchainData;

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  return (
    <div className="exchange">
      <Row className="justify-content-center">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Col lg={12} className="position-relative">
            <div className="loader-form">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                  <rect width="24" height="24" opacity="0" transform="rotate(180 12 12)" />
                  <path
                    fill="#fff"
                    d="M12 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1zM21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1zM6.22 5a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0 0-1.41zM17 8.14a1 1 0 0 0 .69-.28l1.44-1.39A1 1 0 0 0 17.78 5l-1.44 1.42a1 1 0 0 0 0 1.41 1 1 0 0 0 .66.31zM12 18a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1zM17.73 16.14a1 1 0 0 0-1.39 1.44L17.78 19a1 1 0 0 0 .69.28 1 1 0 0 0 .72-.3 1 1 0 0 0 0-1.42zM6.27 16.14l-1.44 1.39a1 1 0 0 0 0 1.42 1 1 0 0 0 .72.3 1 1 0 0 0 .67-.25l1.44-1.39a1 1 0 0 0-1.39-1.44z"
                  />
                </svg>
                {window.ethereum ? (
                  <>
                    {account ? (
                      "Loading"
                    ) : (
                      <a onClick={connectHandler}>
                        <b>Connect</b> your wallet to use the exchange
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    {isMobileDevice() ? (
                      <a href="https://metamask.io/download" target="_blank" rel="noopener noreferrer">
                        <b>Install metamask</b> mobile and use mobile browser metamask
                      </a>
                    ) : (
                      <a href="https://metamask.io/download" target="_blank" rel="noopener noreferrer">
                        <b>Install metamask</b>
                      </a>
                    )}
                  </>
                )}
              </span>
            </div>
            <Row>
              <Col lg={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Buy a coin</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="second" eventKey="second">
                      Stake coins
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <BuyForm />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <StackingForm />
                    {account ? <StakesList /> : undefined}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Col>
        </Tab.Container>
      </Row>
    </div>
  );
};

export default Exchange;
