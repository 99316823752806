import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="copy">
        <p>RUNEX 2022 - ALL rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
