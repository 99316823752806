import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BLOG_ARRAY } from "../utils/constants";
import Subscribe from "./Subscribe";

const Blog = () => {
  return (
    <>
      <section className="blog-page">
        <div className="page-banner">
          <img src={require("../assets/img/logo-white.svg")} alt="" />
          <h1>Our News</h1>
        </div>
        <Container>
          <Row>
            {BLOG_ARRAY.map((item) => (
              <Col lg={item.id === BLOG_ARRAY[0].id ? 12 : 6} key={item.id}>
                <div className="item">
                  <img src={item.id === BLOG_ARRAY[0].id ? item.img_large : item.img_small} alt="" />
                  <div className="info">
                    <span>{item.date}</span>
                    <h3>
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        {item.title}
                      </Link>
                    </h3>
                    <p>
                      {item.short_desc}
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Subscribe />
    </>
  );
};

export default Blog;
