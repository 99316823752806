import React, { useState } from "react";
import Web3 from "web3";

export default function StakesList({ item, withdraw, index }) {
  const { amount, claimable, since, user, percent, withdrawDelay } = item;
  //Constants
  let timeDelay = 1209600000; // 14 days in seconds

  //Triggers
  let unstakeApprove = false;
  let unstakeDelay = false;
  let approveFlag = false;

  if (Number(withdrawDelay) === 0 && Number(since) && +Date.now() - Number(since) * 1000 >= timeDelay) {
    unstakeApprove = true;
  }

  if (Number(withdrawDelay) !== 0 && Number(since) && +Date.now() - Number(since) * 1000 >= timeDelay) {
    approveFlag = true;
  }

  if (Number(withdrawDelay) !== 0 && +Date.now() - Number(withdrawDelay) * 1000 >= timeDelay) {//
    unstakeDelay = true;

  }

  return (
    <div className="stake-list">
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <rect width="256" height="256" fill="none" />
          <path d="M40,192a16,16,0,0,0,16,16H216a8,8,0,0,0,8-8V88a8,8,0,0,0-8-8H56A16,16,0,0,1,40,64Z" fill="#fff" />
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
            d="M40,64V192a16,16,0,0,0,16,16H216a8,8,0,0,0,8-8V88a8,8,0,0,0-8-8H56A16,16,0,0,1,40,64v0A16,16,0,0,1,56,48H192"
          />
          <circle cx="180" cy="144" r="12" />
        </svg>
        User: <b>{user}</b>
      </p>
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M37.9725 27.9747C39.2563 25.9594 40 23.5665 40 21C40 13.8203 34.1797 8 27 8C24.4335 8 22.0407 8.74375 20.0253 10.0275C18.6579 10.1048 17.3336 10.3437 16.0714 10.7254C18.8073 7.8165 22.6915 6 27 6C35.2843 6 42 12.7157 42 21C42 25.3085 40.1835 29.1927 37.2746 31.9286C37.6563 30.6664 37.8953 29.3422 37.9725 27.9747Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M39.9333 28.6028C39.2073 29.8352 38.3101 30.9547 37.2746 31.9286C37.6563 30.6664 37.8953 29.3422 37.9725 27.9747C37.9725 27.9749 37.9726 27.9745 37.9725 27.9747C39.256 25.9598 39.9997 23.5675 40 21.0015C40 21.001 40 21.0005 40 21C40 13.8203 34.1797 8 27 8C24.4335 8 22.0407 8.74375 20.0253 10.0275C20.0256 10.0275 20.0251 10.0275 20.0253 10.0275C18.6579 10.1048 17.3336 10.3437 16.0714 10.7254C17.0454 9.68988 18.1648 8.79277 19.3972 8.06676C21.6173 6.75886 24.2037 6.00629 26.9654 6.00004C26.9769 6.00001 26.9885 6 27 6C35.2843 6 42 12.7157 42 21C42 21.151 41.9978 21.3014 41.9934 21.4513C41.9164 24.0564 41.1752 26.4947 39.9333 28.6028Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M36 27C36 35.2843 29.2843 42 21 42C12.7157 42 6 35.2843 6 27C6 18.7157 12.7157 12 21 12C29.2843 12 36 18.7157 36 27ZM20 22C18.8954 22 18 22.8954 18 24C18 25.1046 18.8954 26 20 26V22ZM22 20V19H20V20C17.7909 20 16 21.7909 16 24C16 26.2091 17.7909 28 20 28V32C19.1307 32 18.3886 31.4449 18.1135 30.6668C17.9295 30.146 17.3582 29.8731 16.8375 30.0572C16.3167 30.2412 16.0438 30.8125 16.2279 31.3332C16.7765 32.8855 18.2568 34 20 34V35H22V34C24.2091 34 26 32.2091 26 30C26 27.7909 24.2091 26 22 26V22C22.8693 22 23.6114 22.5551 23.8865 23.3332C24.0705 23.854 24.6418 24.1269 25.1625 23.9428C25.6833 23.7588 25.9562 23.1875 25.7721 22.6668C25.2235 21.1145 23.7432 20 22 20ZM22 28V32C23.1046 32 24 31.1046 24 30C24 28.8954 23.1046 28 22 28Z"
            clipRule="evenodd"
          />
        </svg>
        Amount: <b>{Web3.utils.fromWei(amount, "ether")}</b>
      </p>
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g data-name="Layer 2">
            <path
              fill="#fff"
              d="M19.21,6.51a1,1,0,0,0-.34,1.37,8,8,0,0,1-6,12.06,7.19,7.19,0,0,1-1.77,0,7.84,7.84,0,0,1-3.84-1.5A8,8,0,0,1,10.43,4.16a1,1,0,1,0-.38-2,10,10,0,0,0-4,17.85,9.82,9.82,0,0,0,4.81,1.88A9.21,9.21,0,0,0,12,22a9.08,9.08,0,0,0,1.11-.07A10,10,0,0,0,20.58,6.86,1,1,0,0,0,19.21,6.51Z"
            />
            <path
              fill="#fff"
              d="M17.88 6.2a1 1 0 0 0 .66-1.75 9.82 9.82 0 0 0-5.43-2.38 1 1 0 0 0-.21 2A7.83 7.83 0 0 1 17.22 6 1 1 0 0 0 17.88 6.2zM16.71 7.29a1 1 0 0 0-1.41 0l-8 8a1 1 0 1 0 1.41 1.41l8-8A1 1 0 0 0 16.71 7.29z"
            />
            <circle fill="#fff" cx="9" cy="9" r="2" />
            <circle fill="#fff" cx="15" cy="15" r="2" />
          </g>
        </svg>
        Percent: <b>{percent}</b>
      </p>
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M24.0391 6C19.5226 6 15.4068 7.49171 12.9723 8.71108C12.7529 8.82095 12.5472 8.92862 12.3561 9.03278C11.9779 9.2389 11.6568 9.43131 11.4001 9.6L14.1701 13.6776L15.4738 14.1967C20.5701 16.768 27.4044 16.768 32.5007 14.1967L33.9811 13.4286L36.6001 9.6C36.2163 9.34413 35.6856 9.03371 35.0324 8.70362C34.9926 8.68351 34.9524 8.66331 34.9117 8.64306C32.4877 7.43697 28.4719 6 24.0391 6ZM17.5968 10.6162C16.6 10.4322 15.6205 10.1786 14.6959 9.88754C16.9772 8.87454 20.377 7.8 24.0391 7.8C26.5764 7.8 28.9755 8.31593 30.9598 8.96919C28.6344 9.29746 26.1529 9.85159 23.789 10.5354C21.9289 11.0735 19.7548 11.0143 17.5968 10.6162ZM33.5567 15.68L33.3116 15.8037C27.7053 18.6324 20.2693 18.6324 14.6629 15.8037L14.4299 15.6861C6.00829 24.9274 -0.421944 41.9971 24.0391 41.9971C48.5001 41.9971 41.9131 24.6085 33.5567 15.68ZM23.0001 24C21.8956 24 21.0001 24.8954 21.0001 26C21.0001 27.1046 21.8956 28 23.0001 28V24ZM25.0001 22V21H23.0001V22C20.791 22 19.0001 23.7909 19.0001 26C19.0001 28.2091 20.791 30 23.0001 30V34C22.1309 34 21.3887 33.4449 21.1137 32.6668C20.9296 32.146 20.3583 31.8731 19.8376 32.0572C19.3169 32.2412 19.0439 32.8125 19.228 33.3332C19.7766 34.8855 21.2569 36 23.0001 36V37H25.0001V36C27.2093 36 29.0001 34.2091 29.0001 32C29.0001 29.7909 27.2093 28 25.0001 28V24C25.8694 24 26.6115 24.5551 26.8866 25.3332C27.0706 25.854 27.6419 26.1269 28.1627 25.9428C28.6834 25.7588 28.9563 25.1875 28.7723 24.6668C28.2236 23.1145 26.7433 22 25.0001 22ZM25.0001 30V34C26.1047 34 27.0001 33.1046 27.0001 32C27.0001 30.8954 26.1047 30 25.0001 30Z"
            clipRule="evenodd"
          />
        </svg>
        Claimed: <b>{Web3.utils.fromWei(claimable, "ether")}</b>
      </p>
      <p>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path fill="none" d="M0 0h48v48h-48z"></path>
          <path
            d="M32.49 15.51c-2.35-2.34-5.42-3.51-8.49-3.51v12l-8.49 8.49c4.69 4.69 12.28 4.69 16.97 0 4.69-4.69 4.69-12.29.01-16.98zm-8.49-11.51c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.96 20-20c0-11.05-8.95-20-20-20zm0 36c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"
            fill="#fff"
          />
        </svg>
        Since: <b>{new Date(+since * 1000).toUTCString().toString()}</b>
      </p>
      {unstakeApprove ? (
        <button
          className="btn-st transparent"
          onClick={async () => {
            await withdraw(amount, index);
          }}
        >
          approve Unstake
        </button>
      ) : unstakeDelay ? (
        <button
          className="btn-st transparent"
          onClick={async () => {
            await withdraw(amount, index);
          }}
        >
          withdraw stake
        </button>
      ) : !approveFlag? (
        <h4>
          You can't approve unstake before 14 days. Unstake will be allowed:{" "}
          <b>{new Date(+since * 1000 + timeDelay).toUTCString().toString()}</b>
        </h4>
      ) : (
        <h4>
          {" "}
          You can't unstake before 14 days. Withdraw will be allowed:{" "}
          <b>{new Date(+since * 1000 + timeDelay).toUTCString().toString()}</b>
        </h4>
      )}
    </div>
  );
}
