export const FAQ_ARRAY = [
  {
    id: 0,
    title: "How to connect a wallet ?",
    desc: "The step-by-step instructions are located on the home page and can be found under Become our Member.",
  },
  {
    id: 1,
    title: "How to buy a token ?",
    desc:
      "If you are using a smartphone, go to runex.org from the metamask mobile app by selecting the browser tab.Connect your wallet to the runex.org web address and make the exchange. To make a purchase through your browser, install the metamask extension, connect your wallet to the runex.org website and make the exchange.",
  },
  {
    id: 2,
    title: "How does the Runex system work ?",
    desc: "You can see how the Runex system works and its components in the WhitePaper in the basement of the site.",
  },
  {
    id: 3,
    title: "What does the ecosystem entail ?",
    desc:
      "The Runex ecosystem includes tools for anonymity, reliability and fast usage of the decentralized exchange via web resources as well as mobile applications. You can find more details about the Runex ecosystem by going to the WhitePaper in the basement of the site.",
  },
];

export const BLOG_ARRAY = [
  {
    id: 17,
    title: "Hello Runex community!",
    date: "July 28, 2022",
    short_desc: "Our team is actively developing and we want to tell you more about what we are currently doing!",
    full_desc: [
      {
        desc: "Our team is actively developing and we want to tell you more about what we are currently doing!",
      },
      {
        desc: "At the moment our team is focused on:",
      },
      {
        desc: "- Matching orders and testing them.",
      },
      {
        desc: "- Creating bridges with other networks.",
      },
      {
        desc: "- Working on connecting non-custodial wallets.",
      },
      {
        desc: "- Working on infographics: adding indices, patterns and other tools.",
      },
      {
        desc: "- Creating algorithms for working with derivatives.",
      },
      {
        desc: "- Testing algorithms for limit orders.",
      },
      {
        desc:
          "Without your faith and support, we would not have managed ! Subscribe to our social networks and keep your finger on the pulse!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-19.jpg"),
    img_large: require("../assets/img/blog/blog-large-19.jpg"),
    btn: "Read More",
  },
  {
    id: 16,
    title: "What's up, #RunexCommunity?",
    date: "July 25, 2022",
    short_desc:
      "Tomorrow at 18:00 (UTC) we will hold an AMA - session with one of the Russian-speaking founders in a Q&A format!",
    full_desc: [
      {
        desc:
          "Tomorrow at 18:00 (UTC) we will hold an AMA - session with one of the Russian-speaking founders in a Q&A format!",
      },
      {
        desc: "At the AMA - session, we will discuss the latest news of the #Runex Runex project.",
      },
      {
        desc: "We will also talk about staking and how it works.",
      },
      {
        desc: "AMA - session will be held in our chat - https://t.me/+DUqL1SL9__EwNmU0",
      },
      {
        desc: "Join, it will be interesting!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-18.jpg"),
    img_large: require("../assets/img/blog/blog-large-18.jpg"),
    btn: "Read More",
  },
  {
    id: 15,
    title: "Dear community, we want to please you with great news!",
    date: "July 24, 2022",
    short_desc: "As we promised, our team has finished the development of the staking!",
    full_desc: [
      {
        desc: "As we promised, our team has finished the development of the staking!",
      },
      {
        desc: "Already now, every REX token holder can use this program and earn additional REX tokens.",
      },
      {
        desc: "How to participate in the steaking:",
      },
      {
        desc: "Transfer tokens to MetaMask",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-17.jpg"),
    img_large: require("../assets/img/blog/blog-large-17.jpg"),
    btn: "Read More",
  },
  {
    id: 14,
    title: "What’s Up Runex Family!",
    date: "July 18, 2022",
    short_desc: "It feels good to benefit our community. That’s why we partnered with a top-ranking company like LocalTrade!",
    full_desc: [
      {
        desc: "It feels good to benefit our community. That’s why we partnered with a top-ranking company like LocalTrade!",
      },
      {
        desc: "LocalTrade is a licensed and regulated crypto exchange that boasts widespread products and services.",
      },
      {
        desc: "Check Them Out: https://localtrade.cc/",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-15.jpg"),
    img_large: require("../assets/img/blog/blog-large-15.jpg"),
    btn: "Read More",
  },
  {
    id: 13,
    title: "Play2Earn is here, ready to fly to another planet?",
    date: "July 18, 2022",
    short_desc: "Very soon you will be able to earn REX tokens, mining them on Mars, the Moon or Jupiter, your choice.",
    full_desc: [
      {
        desc: "Very soon you will be able to earn REX tokens, mining them on Mars, the Moon or Jupiter, your choice.",
      },
      {
        desc:
          "Create your character, choose your tool, collect tokens to fill your rocket and fly to the moon. Gather into clans and compete with other members. Get legendary items for best results among competitors.",
      },
      {
        desc: "You can find more information by following the link - https://docdro.id/1jUgjAp",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-14.jpg"),
    img_large: require("../assets/img/blog/blog-large-14.jpg"),
    btn: "Read More",
  },
  {
    id: 12,
    title: "Dear Runex Community!",
    date: "July 17, 2022",
    short_desc: "Our team works daily to improve our product ! We want every investor to feel comfortable in our community!",
    full_desc: [
      {
        desc: "Our team works daily to improve our product ! We want every investor to feel comfortable in our community!",
      },
      {
        desc:
          "Our experts discovered a vulnerability in the exchange contract that prevents withdrawals. This vulnerability could have led to a development funding problem and negatively impacted our community interests.",
      },
      {
        desc: "These vulnerabilities have been addressed in a new contract that will be posted soon.",
      },
      {
        desc: "All announcements will be on our social media!",
      },
      {
        desc: "Thank you for your faith in us ! It allows us to work for the good of our community!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-13.jpg"),
    img_large: require("../assets/img/blog/blog-large-13.jpg"),
    btn: "Read More",
  },
  {
    id: 11,
    title: "Today we end our first PreSale round!",
    date: "July 15, 2022",
    short_desc: "At 19:00 GMT the second round starts.The value of token in the second round will be $0.18 per token $REX",
    full_desc: [
      {
        desc: "At 19:00 GMT the second round starts.The value of token in the second round will be $0.18 per token $REX",
      },
      {
        desc: "Buy your token now, the price will change soon",
      },
      {
        desc: "Buy token via website - https://runex.org/",
      },
      {
        desc: "Buy token via bot - https://t.me/RunexBounty_Bot",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-12.jpg"),
    img_large: require("../assets/img/blog/blog-large-12.jpg"),
    btn: "Read More",
  },
  {
    id: 10,
    title: "The Runex family",
    date: "July 07, 2022",
    short_desc:
      "The Runex family, our community has gathered people who communicate in different languages and belong to different nationalities!",
    full_desc: [
      {
        desc:
          "The Runex family, our community has gathered people who communicate in different languages and belong to different nationalities! But it does not matter, because we are all united by the idea of Runex! All our hearts beat in unison!",
      },
      {
        desc:
          "Therefore, our team has prepared a small update - we have made Russian white papers so that our Russian-speaking community can study the project and soak up the idea of Runex!",
      },
      {
        desc:
          "It doesn't matter in what country, what language we communicate in. The only important thing is that we all gathered in the Runex community!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-11.jpg"),
    img_large: require("../assets/img/blog/blog-large-11.jpg"),
    btn: "Read More",
  },
  {
    id: 9,
    title: "Runex family, we see more and more people coming to our community!",
    date: "July 04, 2022",
    short_desc: "So we made a post about our project and will also answer the most frequently asked questions!",
    full_desc: [
      {
        desc: "So we made a post about our project and will also answer the most frequently asked questions!",
      },
      {
        desc: "All information about our project can be found on the links below:",
      },
      {
        desc: "📁White papers - told about our technical component and further development plans",
      },
      {
        desc: "(https://docdro.id/GfnjPJd)",
      },
      {
        desc:
          "🔍Audit - our token has been technically audited by FairyProof. You can find all the information about the results of the audit at the link.",
      },
      {
        desc: "(https://fairyproof.com/doc/REX-Token-Audit-Report-052222.pdf)",
      },
      {
        desc: "How to earn Rex tokens - https://t.me/TokenRexPurchase/4",
      },
      {
        desc: "How to buy tokens through the site - https://t.me/TokenRexPurchase/7",
      },
      {
        desc: "How to buy tokens through a bot - https://t.me/TokenRexPurchase/6",
      },
      {
        desc: "How to withdraw tokens from the bot - https://t.me/TokenRexPurchase/8",
      },
      {
        desc:
          "You can see all relevant information about transactions and holders here: https://etherscan.io/address/0x5bf844122db21ae6f787ddda9806aa7dd6489998",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-10.jpg"),
    img_large: require("../assets/img/blog/blog-large-10.jpg"),
    btn: "Read More",
  },
  {
    id: 8,
    title: "Runex family, we want our idea to ignite millions of hearts❗️",
    date: "June 30, 2022",
    short_desc: "We see that you actively use our bot and buy our coins, and it's good!",
    full_desc: [
      {
        desc: "We see that you actively use our bot and buy our coins, and it's good!",
      },
      {
        desc:
          "But we need more people to know about us! So, every week we will put a list of people who invited the most people to our community.",
      },
      {
        desc:
          "You help to grow our community, do a great job, and any work must be paid! Our team understand this and is ready to meet the needs of our community! The most active refferals will receive a nice bonus from Runex Community!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-9.jpg"),
    img_large: require("../assets/img/blog/blog-large-9.jpg"),
    btn: "Read More",
  },
  {
    id: 7,
    title: "Our Community Managers",
    date: "June 29, 2022",
    short_desc:
      "Dear Community, our managers are chatting with you and writing to Influencers to promote our idea to the masses!",
    full_desc: [
      {
        desc:
          "Dear Community, our managers are chatting with you and writing to Influencers to promote our idea to the masses!",
      },
      {
        desc: "A list of our Community Managers:",
      },
      {
        desc: "@runex1",
      },
      {
        desc: "@manager_runex_technlogies",
      },
      {
        desc: "@Runex_CM",
      },
      {
        desc: "@runex_info",
      },

      {
        desc: "@milenarunex1",
      },
      {
        desc: "@AlexsandrRex",
      },
      {
        desc: "@borisrex",
      },
      {
        desc: "@Alexs_Rex",
      },
      {
        desc: "@RunexWell1",
      },
      {
        desc: "@rex_support_1",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-8.jpg"),
    img_large: require("../assets/img/blog/blog-large-8.jpg"),
    btn: "Read More",
  },
  {
    id: 6,
    title: "ATTENTION RUNEX FAMILY!#RunnexBot",
    date: "June 27, 2022",
    short_desc: "Our official bot is out and working! Waiting for you to test it out!",
    full_desc: [
      {
        desc: "Our official bot is out and working! Waiting for you to test it out! ",
      },
      {
        desc: "https://t.me/RunexBounty_Bot",
      },
      {
        desc: "👉🏻EARN REX Tokens",
      },
      {
        desc: "👉🏻Participate in the Pre-Sale",
      },
      {
        desc: "👉🏻Buy REX",
      },
      {
        desc:
          "👉🏻 Invite friends and follow our updates on socials! Excited to hear your feedback and thoughts regarding our bot!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-7.jpg"),
    img_large: require("../assets/img/blog/blog-large-7.jpg"),
    btn: "Read More",
  },
  {
    id: 5,
    title: "Do you want to exchange funds?",
    date: "June 21, 2022",
    short_desc: "Do you wish to exchange funds, not just in #cryptocurrency but also in fiat currency?",
    full_desc: [
      {
        desc: "Do you wish to exchange funds, not just in #cryptocurrency but also in fiat currency?",
      },
      {
        desc: "If so, we’d like to let you know that we are developing an exchange ecosystem!",
      },
      {
        desc: "You can send funds to fiat accounts as you do in a bank.",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-6.png"),
    img_large: require("../assets/img/blog/blog-large-6.png"),
    btn: "Read More",
  },
  {
    id: 4,
    title: "Implementation of business proposals",
    date: "June 15, 2022",
    short_desc: "We are ready to realize our partners' business proposals.",
    full_desc: [
      {
        desc: "We are ready to realize our partners' business proposals.",
      },
      {
        desc: "#Runex will encourage its partners by delegating #REX management tokens and conducting promo actions for them.",
      },
      {
        desc:
          "We will support the #IDO and help with high-level projects by involving the audience and investments for further development.",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-5.png"),
    img_large: require("../assets/img/blog/blog-large-5.png"),
    btn: "Read More",
  },
  {
    id: 3,
    title: "World of financial opportunities",
    date: "June 14, 2022",
    short_desc:
      "We are introducing a world of financial empowerment where no one gets excluded. Everyone can trade #crypto in complete anonymity.",
    full_desc: [
      {
        desc:
          "We are introducing a world of financial empowerment where no one gets excluded. Everyone can trade #crypto in complete anonymity.",
      },
      {
        desc:
          "Get yourself involved with our community and access #decentralized trading services and developments of crypto mixers.",
      },
      {
        desc: "We want you all to be a part of this movement!",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-4.png"),
    img_large: require("../assets/img/blog/blog-large-4.png"),
    btn: "Read More",
  },
  {
    id: 2,
    title: "Audit company: FairyProof",
    date: "April 27, 2022",
    short_desc: "Friends, we are pleased to inform you that we have been successfully audited!",
    full_desc: [
      {
        desc: "Follow the news, there will be a lot of interesting !",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-3.jpg"),
    img_large: require("../assets/img/blog/blog-large-3.jpg"),
    btn: "Read More",
  },
  {
    id: 1,
    title: "Decentralized exchanges",
    date: "April 28, 2022",
    short_desc: "After decentralized exchanges appeared on the crypto-asset market, people began to lean more ",
    full_desc: [
      {
        desc:
          "After decentralized exchanges appeared on the crypto-asset market, people began to lean more towards them because of the direct safety of assets and user security, as private keys remained on hand. But at the same time, the exchange itself was not equipped with many of the necessary tools for trading, unlike the centralized ones, thus making it difficult to act and prevent quick reaction to events in the economy.",
      },
      {
        desc:
          "The Runex team decided to leave all the important DEX tools in the platform under development, namely smart contracts, which guarantee reliability, because they can't be lost or deleted. Also full anonymity, you will not have to disclose your data and identity verification. ",
      },
      {
        desc:
          "Add to this list full-fledged tools inherent in the centralized exchanges, and we get an improved DEX. With the efforts of our team we have a new hybrid trading tool, which will be a discovery for you this year!",
      },
      {
        desc:
          '- What ranex technology will bring to the cryptocurrency world  Innovative product, which combines all main positive sides of both exchanges will not only make your life easier, but will also change the world of crypto assets. What will change? The problem of narrow spreads will disappear, you will be able to create and set a more profitable for you price, determine the depth and volume of trade by creating in Runex an algorithmic list that displays both buyers and sellers orders, including details like exact buy or sell price and order volume. We have developed an algorithm for borrowing money, in which pledged assets are frozen for a certain period of time by blocking them with a smart contract. If there are later losses comparable to the pledged assets, the system will automatically put an order to sell them.  You will have the opportunity to open trades on the decrease, the so-called "short positions". This way the reward function will be realized, when the price of an asset goes down. The Runex Exchange will make assets available for sale by freezing the buyer\'s collateral. With the proceeds you can then buy the same asset cheaper and return it to the exchange, keeping the difference in price. After that, the collateral will be unfrozen and available for use.  The problem of anonymity is no longer an issue. Our team has implemented a mixer algorithm, which means that your digital assets are divided into smaller pieces and mixed with the assets of other users. The final owner then receives the amount sent to him or her, not all of it, but in small pieces and from different randomly selected users. Congratulations, this leaves your transactions completely sealed! The future is already here. Thanks to the great features provided to its users by the Runex team you will be able to comfortably perform transactions without worrying about fees and anonymity.',
      },
    ],
    img_small: require("../assets/img/blog/blog-small-2.jpg"),
    img_large: require("../assets/img/blog/blog-large-2.jpg"),
    btn: "Read More",
  },
  {
    id: 0,
    title: "We design our objectives based on users’ wishes.",
    date: "April 28, 2022",
    short_desc: "Runex aims to create a fully automated decentralized platform that functions",
    full_desc: [
      {
        desc:
          "Runex aims to create a fully automated decentralized platform that functions based on smart contracts and provides comprehensive tools for crypto asset trades. ",
      },
      {
        desc: "Join Runex today",
      },
    ],
    img_small: require("../assets/img/blog/blog-small-1.jpg"),
    img_large: require("../assets/img/blog/blog-large-1.jpg"),
    btn: "Read More",
  },
];
