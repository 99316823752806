import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <NavLink to="/" className="link" activeclass="active" exact="true">
            <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.47059 18.0672V11.7729H12.7059V18.0672H18V9.6748H21.1765L10.5882 0.233398L0 9.6748H3.17647V18.0672H8.47059Z"
                fill="white"
              />
            </svg>
            Home
          </NavLink>
          <NavLink to="blog" className="link" activeclass="active">
            <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9921 16.9167C15.016 17.8252 15.3723 18.1335 16.4913 18.4021H2C0.895432 18.4021 0 17.5066 0 16.4021V0.577667H14.9921V16.9167Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9906 15.9259C19.0147 16.1267 18.9925 16.3304 18.9257 16.5214C18.8588 16.7125 18.7491 16.8861 18.6048 17.0291C18.4605 17.1721 18.2853 17.2808 18.0924 17.347C17.8995 17.4132 17.694 17.4352 17.4913 17.4113C16.0986 17.4113 15.9921 15.9259 15.9921 15.9259L15.9976 7.50885H18.9906V15.9259Z"
                fill="white"
              />
              <rect x="2" y="4" width="6" height="6" fill="#0C0F18" />
              <path d="M2 12H13" stroke="black" />
              <path d="M2 14H8" stroke="black" />
            </svg>
            Blog
          </NavLink>
          <NavLink to="faq" className="link" activeclass="active">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none" />
              <path
                fill="#fff"
                d="M128.00146,23.99963a104,104,0,1,0,104,104A104.11791,104.11791,0,0,0,128.00146,23.99963ZM128.002,192a12,12,0,1,1,12-12A12,12,0,0,1,128.002,192Zm7.99951-48.891v.89551a8,8,0,1,1-16,0v-8a8.0004,8.0004,0,0,1,8-8,20,20,0,1,0-20-20,8,8,0,0,1-16,0,36,36,0,1,1,44,35.10449Z"
              />
            </svg>
            FAQ
          </NavLink>
          <NavLink to="/contacts" className="link" activeclass="active">
            <svg viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.48062L10.052 9.52219C10.6147 9.97237 11.418 9.95877 11.9652 9.48977L21 1.74565V14C21 14.5523 20.5523 15 20 15H1C0.447715 15 0 14.5523 0 14V1.48062ZM1.35078 0H19.9634L10.978 7.70178L1.35078 0Z"
                fill="white"
              />
            </svg>
            Contacts
          </NavLink>
        </Nav>
        <a href="https://t.me/RunexSupportOfficial" target="_blank" rel="noopener noreferrer" className="social">
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.9658 1.57781L15.2469 14.2817C15.0418 15.1783 14.5068 15.4014 13.7466 14.9792L9.60391 11.9545L7.60503 13.8594C7.38388 14.0785 7.19882 14.2619 6.77248 14.2619L7.07007 10.0816L14.7482 3.20762C15.082 2.91277 14.6758 2.74936 14.2293 3.04425L4.73732 8.96581L0.650908 7.69864C-0.237966 7.42366 -0.254024 6.81794 0.835897 6.39557L16.8195 0.294685C17.5595 0.0197447 18.2071 0.45802 17.9658 1.57781Z"
              fill="white"
            />
          </svg>
          Write to us
        </a>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
