import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { FAQ_ARRAY } from "../utils/constants";
import Subscribe from "./Subscribe";

const FAQ = () => {
  return (
    <>
      <section className="faq">
        <div className="page-banner">
          <img src={require("../assets/img/logo-white.svg")} alt="" />
          <h1>FAQs</h1>
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <Accordion defaultActiveKey="0">
                {FAQ_ARRAY.map((item, i) => (
                  <Accordion.Item eventKey={item.id} key={i}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.desc}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
      <Subscribe />
    </>
  );
};

export default FAQ;
