import React, { useContext, useEffect } from "react";
// import { Col, Nav, Row, Tab } from "react-bootstrap";

// Context import
import BlockchainContext from "../context/BlockchainContext";

// Components import
import Banner from "./components/Banner";
import About from "./components/About";
import RoadMap from "./components/RoadMap";
import Work from "./components/Work";
import BlogHome from "./components/BlogHome";

const Home = () => {
  const blockchainContext = useContext(BlockchainContext);
  const { blockchainData } = blockchainContext;

  useEffect(() => {
    if (blockchainData.loading) {
      document.querySelector(".loader-form").classList.add("active");
    } else {
      document.querySelector(".loader-form").classList.remove("active");
    }
  });

  return (
    <>
      <Banner />
      <About />
      <RoadMap />
      <Work />
      <BlogHome />
    </>
  );
};

export default Home;
