import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { send } from "emailjs-com";
import Subscribe from "./Subscribe";

const Contact = () => {
  const [defaultValuesRequest, setDefaultValuesRequest] = useState({
    name: "",
    email: "",
    textarea: "",
  });

  const handleChange = (e) => {
    setDefaultValuesRequest({ ...defaultValuesRequest, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (defaultValuesRequest.name === "" || defaultValuesRequest.email === "" || defaultValuesRequest.textarea === "") {
      document.querySelector(".error").classList.add("active");
      document.querySelector(".success").classList.remove("active");
    } else {
      send("service_3fhkj5v", "template_g60rt8r", defaultValuesRequest, "V2wBZBAcZG5xQxApb").then(
        (result) => {
          document.querySelector(".error").classList.remove("active");
          document.querySelector(".success").classList.add("active");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  useEffect(() => {
    setDefaultValuesRequest({
      ...defaultValuesRequest,
    });
  }, []);

  return (
    <>
      <section className="contact">
        <div className="page-banner">
          <img src={require("../assets/img/logo-white.svg")} alt="" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col xl={9} lg={9} md={12}>
              <div className="box">
                <h2>Have a question</h2>
                <p>Fill up the Form and ou team will get back to within 24 hrs</p>
                <Form>
                  <Row>
                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <Form.Control
                          name="name"
                          type="text"
                          placeholder="Name"
                          value={defaultValuesRequest.name}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={defaultValuesRequest.email}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <InputGroup>
                        <Form.Control
                          name="textarea"
                          as="textarea"
                          placeholder="Type your Messege"
                          value={defaultValuesRequest.textarea}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <div className="valid">
                    <span className="invalid-feedback error">Message not sent! Please fill in all the fields.</span>
                    <span className="invalid-feedback success">Message sent successfully!</span>
                  </div>

                  <button type="button" onClick={onSubmit} className="btn-st transparent">
                    Send Message
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Subscribe />
    </>
  );
};

export default Contact;
