import React, { useEffect, useState, useContext } from "react";
//Components
import StakeItem from "./StakeItem";
import Web3 from "web3";
//Contexts
import StakeContext from "../../context/StakeContext";

const BN = Web3.utils.BN;
const fromWei = Web3.utils.fromWei
const toWei = Web3.utils.toWei

export default function StakesList() {
  const { withdraw, summery, calculateTotalRewards } = useContext(StakeContext);
  const { total_amount, stakes } = summery;
  const nullAddress = "0x0000000000000000000000000000000000000000";

  return (
    <div className="stake">
      <div className="amounts">
        <h5>
          Total stakes amount:
          <span>
            {fromWei(total_amount, "ether")}
            <b>
              <img src={require("../../assets/img/runex.svg")} alt="" />
              REX
            </b>
          </span>
        </h5>
        <h5>
          Total rewards amount:
          <span>
            {calculateTotalRewards(stakes)}
            <b>
              <img src={require("../../assets/img/runex.svg")} alt="" />
              REX
            </b>
          </span>
        </h5>
      </div>
      {stakes.length > 0 ? (
          stakes.map((item, index) => {
          if (item.user !== nullAddress) {
            return <StakeItem key={index+'stake'} item={item} withdraw={withdraw} index={index} />;
          }
        }).reverse()
      ) : (
        <p className="stake-list align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#bc3036"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="9" x2="15" y2="15"></line>
            <line x1="15" y1="9" x2="9" y2="15"></line>
          </svg>
          No stake
        </p>
      )}
    </div>
  );
}
