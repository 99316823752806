import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RoadMap = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="road">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <div className="title">
              <img src={require("../../assets/img/logo-white.svg")} alt="" />
              <h1>Runex Timeline</h1>
            </div>
            <Row className="map">
              <Col lg={5} md={6} className="hidden-md">
                <div className="item">
                  <p>
                    Conducting a bounty campaign to attract the target audience to the project.Conducting an advertising
                    campaign.Passing an audit of the project for reliability.Inclusion of a stacking function for Rex management
                    tokens.Passing an IEO to re-engage the audience and fund further development.Connecting an external fast
                    crypto exchange, part of the exchange commissions will be sent to support Rex liquidity.
                  </p>
                  <h3 className="ml-20">
                    <b>Q2</b>2022
                  </h3>
                </div>
                <div className="item">
                  <p>
                    Testing trading algorithms, launching a working prototype for study and analysis.Connecting cryptocurrency
                    mixers and testing the overall algorithmic process to identify vulnerabilities and fine-tune possible
                    nuances.
                  </p>
                  <h3 className="ml-20">
                    <b>Q3</b>2022
                  </h3>
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="road-logo">
                  <img src={require("../../assets/img/logo-road.svg")} alt="" />
                  <svg width="441" height="441" viewBox="0 0 441 441" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.15" cx="220.5" cy="220.5" r="157.5" stroke="white" />
                    <circle opacity="0.05" cx="220.5" cy="197.5" r="134.5" stroke="white" />
                    <circle opacity="0.05" cx="222.5" cy="244.5" r="134.5" stroke="white" />
                    <circle opacity="0.15" cx="222.5" cy="235.5" r="203.5" stroke="white" />
                    <circle opacity="0.05" cx="220.5" cy="204.5" r="203.5" stroke="white" />
                    <circle opacity="0.05" cx="220.5" cy="220.5" r="219.5" stroke="white" />
                    <circle opacity="0.13" cx="221.5" cy="220.5" r="110.5" fill="black" />
                  </svg>
                </div>
              </Col>
              <Col lg={5} md={6} className="hidden-md">
                <div className="item mb-70">
                  <h3 className="mr-20">
                    <b>Q4</b>2022
                  </h3>
                  <p>
                    Launch of the exchange on the basis of its own algorithms, continuing testing of the working prototype with
                    the connection of marginal algorithms under development. Introduction of an independent form of voting by
                    blocking Rex control tokens, to ensure the reliability of the social component. Development and connection
                    of a mobile application to optimize work on android and ios devices.
                  </p>
                </div>
                <div className="item">
                  <h3 className="mr-20">
                    <b>Q1</b>2023
                  </h3>
                  <p>
                    Working on the connection of margin algorithms.Introducing replacement tokens (for assets that do not
                    support smart contracts).Developing support for NFT trading.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="hidden-lg">
              <Slider {...settings}>
                <div className="item">
                  <h3>
                    <b>Q2</b>2022
                  </h3>
                  <p>
                    Conducting a bounty campaign to attract the target audience to the project.Conducting an advertising
                    campaign.Passing an audit of the project for reliability.Inclusion of a stacking function for Rex management
                    tokens.Passing an IEO to re-engage the audience and fund further development.Connecting an external fast
                    crypto exchange, part of the exchange commissions will be sent to support Rex liquidity.
                  </p>
                </div>
                <div className="item">
                  <h3>
                    <b>Q3</b>2022
                  </h3>
                  <p>
                    Testing trading algorithms, launching a working prototype for study and analysis.Connecting cryptocurrency
                    mixers and testing the overall algorithmic process to identify vulnerabilities and fine-tune possible
                    nuances.
                  </p>
                </div>
                <div className="item">
                  <h3>
                    <b>Q4</b>2022
                  </h3>
                  <p>
                    Launch of the exchange on the basis of its own algorithms, continuing testing of the working prototype with
                    the connection of marginal algorithms under development. Introduction of an independent form of voting by
                    blocking Rex control tokens, to ensure the reliability of the social component. Development and connection
                    of a mobile application to optimize work on android and ios devices.
                  </p>
                </div>
                <div className="item">
                  <h3>
                    <b>Q1</b>2023
                  </h3>
                  <p>
                    Working on the connection of margin algorithms.Introducing replacement tokens (for assets that do not
                    support smart contracts).Developing support for NFT trading.
                  </p>
                </div>
              </Slider>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RoadMap;
