import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BLOG_ARRAY } from "../utils/constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Subscribe from "./Subscribe";

const BlogDetails = () => {
  const { id } = useParams();
  const [blogItem, setBlogItem] = useState(null);

  useEffect(() => {
    if (id) {
      const integerId = parseInt(id);
      const temp = BLOG_ARRAY.find((elem) => elem.id === integerId);
      setBlogItem(temp);
    }
  }, [id]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return blogItem ? (
    <>
      <section className="blog-details">
        <div className="page-banner">
          <img src={require("../assets/img/logo-white.svg")} alt="" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h6>{blogItem.date}</h6>
              <h1>
                {blogItem.title}
                <a href="/blog">
                  <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                  </svg>
                  All News
                </a>
              </h1>
              <img src={blogItem.img_large} className="image" alt="" />
              <ul>
                <p>Share:</p>
                <li>
                  <a href="https://twitter.com/RunexTech" target="_blank" rel="noopener noreferrer">
                    <img src={require("../assets/img/social/twitter.svg")} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/RunexTechnologiesOfficial" target="_blank" rel="noopener noreferrer">
                    <img src={require("../assets/img/social/telegram-plane.svg")} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/runex-technologies-2572ba237/" target="_blank" rel="noopener noreferrer">
                    <img src={require("../assets/img/social/linkedin.svg")} alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/runextechnologies/" target="_blank" rel="noopener noreferrer">
                    <img src={require("../assets/img/social/instagram.svg")} alt="" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={10}>
              {blogItem.full_desc.map((item, i) => (
                <p key={i}>{item.desc}</p>
              ))}
            </Col>
          </Row>
          <Row>
            <h1 className="v2">Popular</h1>
          </Row>
          <Row className="hidden-md">
            {BLOG_ARRAY.slice(0, 3).map((item, i) => (
              <Col lg={4}>
                <div className="popular-item" key={i}>
                  <img src={item.img_small} alt="" />
                  <div className="info">
                    <span>{item.date}</span>
                    <h3>
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        {item.title}
                      </Link>
                    </h3>
                    <p>
                      {item.short_desc}
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="hidden-lg">
            <Slider {...settings}>
              {BLOG_ARRAY.slice(0, 3).map((item, i) => (
                <div className="popular-item" key={i}>
                  <img src={item.img_small} alt="" />
                  <div className="info">
                    <span>{item.date}</span>
                    <h3>
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        {item.title}
                      </Link>
                    </h3>
                    <p>
                      {item.short_desc}
                      <Link
                        to={{
                          pathname: `/blog/${item.id}`,
                        }}
                      >
                        <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </Row>
        </Container>
      </section>
      <Subscribe />
    </>
  ) : (
    <>Error</>
  );
};

export default BlogDetails;
