import React, { useEffect, useState, useContext } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

// Contexts import
import ExchangeContext from "../../context/ExchangeContext";
import BlockchainContext from "../../context/BlockchainContext";

export default function BuyForm() {
  const { buyToken, balances } = useContext(ExchangeContext);
  const { blockchainData, setBlockchainData, reboot, addTokenInfoToMetamask } = useContext(BlockchainContext);
  const { allowance, error } = blockchainData;
  const { runBalance, tetherBalance, exchangeBalance } = balances;
  const [amountPrice, setAmountPrice] = useState("");
  const [tokenQuantity, setTokenQuantity] = useState("0");
  const [loader, setLoader] = useState(true);
  const [approved, setApproved] = useState(false);

  const [tokenBalances, setTokenBalance] = useState({
    runBalance: "0",
    tetherBalance: "0",
    exchangeBalance: "0",
  });

  const calculateTokenQuantity = (event) => {
    const rate = 0.2;
      setAmountPrice(event.target.value);
      const quantity = event.target.value;

      const tokensToBuy = (quantity / rate).toString().split(".");
      //TODO: Correct out
      setTokenQuantity(
        tokensToBuy[0] +
          (tokensToBuy[1] && tokensToBuy[1].length > 6 ? "." + tokensToBuy[1].slice(0, tokensToBuy[1].length - 1) : "")
      );
    }

  const checkApprove = () => {
    if (+allowance > 0) {
      setAmountPrice(allowance);
      setApproved(true);
    } else {
      setApproved(false);
    }
  };

  const buyHandler = async (e) => {
    e.preventDefault();

    if (amountPrice) {
      const result = await buyToken(amountPrice);

      if (result) {
        setTokenBalance({
          runBalance,
          tetherBalance,
          exchangeBalance,
        });

        await reboot();
        setBlockchainData({ ...blockchainData, allowance: "0" });
        // setApproved(false);
        setLoader(!loader);
      } else {
        await reboot();
        setLoader(!loader);
      }
    }
  };

  useEffect(() => {
    setTokenBalance({
      runBalance,
      tetherBalance,
      exchangeBalance,
    });
  }, [loader, balances, error, blockchainData]);



  return (
    <>
      <div className="form-block">
        <Form onSubmit={buyHandler}>
          <Row>
            <Form.Group as={Col} lg="6" md="12" className="pr-30 mb-30">
              <Form.Label htmlFor="input">
                <span className="label">
                  Input <b>amount</b>
                </span>
                <span>
                  <b>Balance:</b> {tokenBalances.tetherBalance === "0" ? tetherBalance : tokenBalances.tetherBalance}
                </span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id="input"
                  name="input"
                  type="number"
                  placeholder={"Enter amount"}
                  value={amountPrice}//{allowance === "0" ? amountPrice : amountPrice}
                  step="0.0001"
                  min="0.0001"
                  max={+tetherBalance > 10000 ? 10000 : tetherBalance}
                  onChange={(event) => {
                    // if (!approved) {
                      calculateTokenQuantity(event);
                    // } else {
                    //   event.target.value = allowance;
                    // }
                  }}
                  required
                />
                <InputGroup.Text>
                  <img src={require("../../assets/img/usdt.svg")} alt="" />
                  USDT
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <svg width="42" height="21" viewBox="0 0 42 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="vector">
              <path d="M3 2L8.48026 10.5L3 19" stroke="#CFFF11" strokeWidth="5" />
              <path opacity="0.6" d="M9 2L14.4803 10.5L9 19" stroke="#CFFF11" strokeWidth="5" />
              <path opacity="0.5" d="M15 2L20.4803 10.5L15 19" stroke="#CFFF11" strokeWidth="5" />
              <path opacity="0.4" d="M21 2L26.4803 10.5L21 19" stroke="#CFFF11" strokeWidth="5" />
              <path opacity="0.3" d="M27 2L32.4803 10.5L27 19" stroke="#CFFF11" strokeWidth="5" />
              <path opacity="0.2" d="M33 2L38.4803 10.5L33 19" stroke="#CFFF11" strokeWidth="5" />
            </svg>

            <Form.Group as={Col} lg="6" md="12" className="pl-30 mb-30">
              <Form.Label htmlFor="output">
                <span className="label">
                  Output <b>amount</b>
                </span>
                <span>
                  <b>Balance:</b> {tokenBalances.runBalance === "0" ? runBalance : tokenBalances.runBalance}
                </span>

              </Form.Label>
              <InputGroup>
                <Form.Control id="output" name="output" type="text" placeholder="0" value={tokenQuantity} readOnly />
                <InputGroup.Text>
                  <img src={require("../../assets/img/runex.svg")} alt="" />
                  REX
                </InputGroup.Text>
              </InputGroup>
              <Form.Text className="ms-1 text-muted">Exchange Rate 1 REX = {20 / 100} USDT</Form.Text>
            </Form.Group>
          </Row>
          <div className="text-center">
            {approved ? (
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ad93dc"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="8" x2="12" y2="12" />
                  <line x1="12" y1="16" x2="12.01" y2="16" />
                </svg>
                {allowance + ' USDT to buy REX approved to continue click "SWAP!"'}
              </h5>
            ) : (
              <span></span>
            )}
            <div className="d-flex">
              <button type="submit" className="btn-st transparent">
                SWAP!
              </button>
              <button type="button" className="btn-st transparent" onClick={addTokenInfoToMetamask}>
                Add Token
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}
