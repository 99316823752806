import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BLOG_ARRAY } from "../../utils/constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogHome = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="blog">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="title">
              <img src={require("../../assets/img/logo-white.svg")} alt="" />
              <h1>Our News</h1>
            </div>
          </Col>
        </Row>
        <Row className="hidden-md">
          {BLOG_ARRAY.slice(0, 3).map((item) => (
            <Col lg={4} key={item.id}>
              <div className="item">
                <img src={item.img_small} alt="" />
                <div className="info">
                  <span>{item.date}</span>
                  <h3>
                    <Link
                      to={{
                        pathname: `/blog/${item.id}`,
                      }}
                    >
                      {item.title}
                    </Link>
                  </h3>
                  <p>
                    {item.short_desc}
                    <Link
                      to={{
                        pathname: `/blog/${item.id}`,
                      }}
                    >
                      <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                      </svg>
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="hidden-lg">
          <Slider {...settings}>
            {BLOG_ARRAY.slice(0, 3).map((item) => (
              <div className="item" key={item.id}>
                <img src={item.img_small} alt="" />
                <div className="info">
                  <span>{item.date}</span>
                  <h3>
                    <Link
                      to={{
                        pathname: `/blog/${item.id}`,
                      }}
                    >
                      {item.title}
                    </Link>
                  </h3>
                  <p>
                    {item.short_desc}
                    <Link
                      to={{
                        pathname: `/blog/${item.id}`,
                      }}
                    >
                      <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                      </svg>
                    </Link>
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </Row>
        <Row>
          <Col lg={12} className="text-center">
            <Link to="blog" className="btn-more">
              All News
              <span>
                <svg width="10" height="13" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.5 0.5L4.5 4.5L0.5 8.5" stroke="#47B2FF" />
                </svg>
              </span>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogHome;
